/**
Synapse - adaptive theme
.col-xs- <544px Extra small
.col-sm- ≥544px Small
.col-md- ≥768px Medium
.col-lg- ≥992px Large
.col-xl- ≥1200px Extra large
Gutter width	1.875rem / 30px (15px on each side of a column)
*/
#synapse-copy {
  padding: 10px 0 0 0;
  font-size: 14px;
}

footer .copy {
  font-size: 14px;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 15px;
}
footer .synapse-link {
  font-style: italic;
  text-decoration: none;
}
footer .footer-logo img {
  max-width: 100%;
}
footer .synapse {
  padding-top: 9px;
}
footer .synapse img {
  position: relative;
  top: -7px;
}
